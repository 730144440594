<template>
  <div>
    <Buttons
      :textCancel="$t('GENERAL.CANCEL')"
      v-on:cancel="cancel"
      :cancel_loading="cancel_loading"
      :textSave="$t('GENERAL.SAVE')"
      v-on:save="save"
      :save_loading="save_loading"
      :btnSaveNew="false"
      :btnDuplicate="false"
      :btnDelete="false"
    />
    <v-card elevation="2">
      <v-card-title> {{ $t("TIMETABLE.NEW_TIMETABLE") }} </v-card-title>
      <v-card-text>
        <TimetableTemplate
          :timetableTemplate="timetableTemplate"
          :showName="true"
          showCourses="false"
          v-on:save_timetable_template="save_timetable_template($event)"
          ref="timetable"
        ></TimetableTemplate>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TimetableTemplate from "@/view/content/components/forms/TimetableTemplate";
import { STORE_PRODUCT_TIMETABLE_TEMPLATE } from "@/core/services/store/general/time_table.module";
import Buttons from "@/view/content/components/Buttons";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      save_loading: false,
      timetableTemplate: {
        name: "",
        campusId: 0,
        product_timetable_template_items: [],
      },
      timetableTemplate_modified: {},
    };
  },
  name: "timetables",
  components: {
    TimetableTemplate,
    Buttons,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Timetables" }]);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Timetables", route: "/configure/timetables/" },
      { title: this.$t("TIMETABLE.NEW_TIMETABLE") },
    ]);
    this.timetableTemplate.campusId = this.campus.campusId;
  },
  computed: {
    ...mapGetters(["campus"]),
  },
  methods: {
    cancel() {
      this.$router.push("/configure/timetables");
    },
    save_timetable_template(timetableTemplate_modified) {
      this.timetableTemplate_modified = timetableTemplate_modified;
    },
    async save() {
      this.$refs.timetable.$refs.observer.validate().then(async data => {
        if (data) {
          this.save_loading = true;
          await this.$store
            .dispatch(
              STORE_PRODUCT_TIMETABLE_TEMPLATE,
              Vue.util.extend({}, this.timetableTemplate_modified)
            )
            .then(result => {
              result;
              this.dialog = false;
              this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
                title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
                variant: "success",
                solid: true,
              });
              this.save_loading = false;
              setTimeout(() => {
                this.$router.push("/configure/timetables");
              }, 1000);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-floating {
  position: fixed;
  top: 80px;
  z-index: 99999;
}
</style>
